const dev = {
  domain: "http://localhost:7927",
  api_domain: "http://localhost:7927/api",
  netsuite_url: "https://5615243.app.netsuite.com",
  tms_uploads_bucket: "magna-tms-uploads",
  tms_uploads_cdn: "https://d35jitumpuemmk.cloudfront.net",  
  google_maps_key: "AIzaSyAgOgGQ9BhLC5Udq2-9-d2ek8UZaqWUMNE",
  env: "uat"
};

const uat = {
  domain: "https://uat-api.magnarouting.com",
  api_domain: "https://uat-api.magnarouting.com/api",
  netsuite_url: "https://5615243-sb1.app.netsuite.com",
  tms_uploads_bucket: "magna-tms-uploads",
  tms_uploads_cdn: "https://d35jitumpuemmk.cloudfront.net",
  google_maps_key: "AIzaSyAgOgGQ9BhLC5Udq2-9-d2ek8UZaqWUMNE",
  env: "uat"
};

const prod = {
  domain: "https://api.magnarouting.com",
  api_domain: "https://api.magnarouting.com/api",
  netsuite_url: "https://5615243.app.netsuite.com",
  tms_uploads_bucket: "magna-tms-uploads",
  tms_uploads_cdn: "https://d35jitumpuemmk.cloudfront.net",
  google_maps_key: "AIzaSyAgOgGQ9BhLC5Udq2-9-d2ek8UZaqWUMNE",
  env: "prod"
};

module.exports = uat;